/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import logo1 from "../images/logo1.png"
import phone from "../images/call-answer.svg"
import email from "../images/email-filled-closed-envelope.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            height: "auto",
            backgroundColor: "rgb(43, 43, 43)",
            color: "white",
          }}
        >
          <div className="footer-wrapper">
            <ul className="footer-info">
              <Link to="/firmast/">
                <li>Kontakt</li>
              </Link>
              <li>
                <img src={phone} alt="phone" width="16"></img>{" "}
                <a href="tel:+37253480606" style={{ color: "white" }}>
                  +372 5348 0606
                </a>
              </li>
              <li>
                <img src={email} alt="email" width="16"></img>
                <a href="mailto:info@epo.ee" style={{ color: "white" }}>
                  info@epo.ee
                </a>
              </li>
            </ul>
            {/* <Link to="/">
              <img id="footer-logo" src={logo1} alt="epo.ee" width="150"></img>
            </Link> */}
          </div>

          <hr className="footer-line"></hr>
          <div className="footer-bottom">© {new Date().getFullYear()}</div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
