import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

//import logo1 from "../images/logo1.png"
import logo1 from "../images/WSlogo_transparent.png"
import phone from "../images/call-answer.svg"
import email from "../images/email-filled-closed-envelope.svg"

function shoot() {
  document.getElementById("mobileMenuId").classList.toggle("active")
  document.getElementById("mobileOverlay").classList.toggle("active")
}

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
      }}
    >
      <div className="mobile-overlay" id="mobileOverlay" onClick={shoot}></div>
      <div id="mobileMenuId" className="mobile-menu">
        <p className="menu-close" onClick={shoot}>
          ✖
        </p>
        <ul className="nav mobile-navbar">
          <li>
            <Link
              to="/epoporandad/"
              activeStyle={{ color: "#0000f0" }}
              partiallyActive={true}
            >
              Epopõrandad
            </Link>
          </li>
          <li>
            <Link
              to="/tostetudporandad/"
              activeStyle={{ color: "#0000f0" }}
              partiallyActive={true}
            >
              Tõstetud põrandad
            </Link>
          </li>
          <li>
            {/* <Link
              to="/ruumijagajad/"
              activeStyle={{ color: "#0000f0" }}
              partiallyActive={true}
            > */}

            <a href="https://winterland.ee">Ruumijagajad</a>
            {/* </Link> */}
          </li>
          <li>
            <Link
              to="/platvormid/"
              activeStyle={{ color: "#0000f0" }}
              partiallyActive={true}
            >
              Platvormid
            </Link>
          </li>
          <li className="no-border">
            <Link
              to="/lavad/"
              activeStyle={{ color: "#0000f0" }}
              partiallyActive={true}
            >
              Lavad ja tribüünid
            </Link>
          </li>
          <div className="header-logo mobile-menu-logo">
            <Link to="/">
              <img src={logo1} alt="epo.ee" width="100"></img>
            </Link>
          </div>
        </ul>
      </div>

      <div
        className="header-top-bar"
        style={{
          background: `rgb(43, 43, 43)`,
          height: 30,
        }}
      >
        <div className="contact-icons">
          <span className="contact-phone">
            <img src={phone} alt="epo.ee" width="17"></img>
            <p>
              <a href="tel:+37253480606" style={{ color: "white" }}>
                +372 5348 0606
              </a>
            </p>
          </span>
          <span className="contact-email">
            <img src={email} alt="epo.ee" width="17"></img>
            <p>
              <a href="mailto:info@epo.ee" style={{ color: "white" }}>
                info@epo.ee
              </a>
            </p>
          </span>
        </div>
      </div>

      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              id="mobileToggler"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#myNavbar"
              onClick={shoot}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="nav navbar-nav navbar-right">
              <Link
                to="/epoporandad/"
                activeStyle={{ color: "#0000f0" }}
                partiallyActive={true}
              >
                <li>Epopõrandad</li>
              </Link>
              <Link
                to="/tostetudporandad/"
                activeStyle={{ color: "#0000f0" }}
                partiallyActive={true}
              >
                <li>Tõstetud põrandad</li>
              </Link>
              {/* <Link
                to="/ruumijagajad/"
                activeStyle={{ color: "#0000f0" }}
                partiallyActive={true}
              > */}
              <li>
                <a href="https://winterland.ee">Ruumijagajad
                </a>
              </li>
              {/* </Link> */}
              <Link
                to="/platvormid/"
                activeStyle={{ color: "#0000f0" }}
                partiallyActive={true}
              >
                <li>Platvormid</li>
              </Link>
              <Link
                to="/lavad/"
                activeStyle={{ color: "#0000f0" }}
                partiallyActive={true}
              >
                <li>Lavad ja tribüünid</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="header-logo">
          <Link to="/">
            <img src={logo1} alt="epo.ee" width="100"></img>
          </Link>
        </div>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
/*ReactDOM.render(
  document.getElementById("mobileToggler").addEventListener("click", function())

function mobileFunction() {
  document.getElementById("mobileMenuId").classList.add("active")
);*/

export default Header
